import React from 'react';
import BannerCard from '../banner-card/BannerCard';
import Project from '../project/ProjectBox';
import './Projects.css';

export default function Projects(props) {

    return (
            <section className="card-section cards-slider">
                <div className="content-container">
                    
                    <div className="card-wrapper">
                        
                        {
                            props.projects.length === 0 && "לא נמצאו התנדבויות.."
                        }
                        {     
                            props.projects?.map((item, i) =>  (
                                <>
                                {i === 5 && <BannerCard 
                                    leadingColor={props.leadingColor}
                                    key={`k_${i}`}
                                    banner_img_src={props.partner.partnerConfig.adImageUrl} 
                                    banner_img_alt={props.partner.partnerConfig.adMessageAltText}
                                    banner_title={props.partner.partnerConfig.adMessage}
                                    banner_content={props.partner.partnerConfig.adMessageSubTitle}
                                />}
                                <Project partnerName={props.partner.partnerLink} key={i} project_fields={item}/></>
                            ))
                        }

                    </div>
                    {/* <div className="footer-section-link-wrapper"> 
                        <a href="index-column-mobile-card" className="footer-section-link"> הצג עוד </a> 
                    </div> */}
                </div>
            </section>
    )
}
