import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import Projects from '../../components/projects/Projects';
import BannerText from '../../components/banner-text/BannerText';
import Hero from '../../components/hero/Hero';
import Loader from '../../components/loader/Loader';
import Utils from '../../services/utils/Utils';
import CrmUtils from '../../services/crm-utils/CrmUtils';
import { useDispatch, useSelector } from 'react-redux';
import { setAllProjects, setDisplayProjects, setHeaderMainLink } from '../../services/store/GenericReducer';

export default function Home(props) {

  const store = useSelector(state => state.reducer)
  const [projects, setProjects] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [filtersArray,setFiltersArray] = useState(props.partner.partnerConfig.filterTypes);
  const partnerColor = props.partner.partnerConfig.leadingColor;
  const dispatch = useDispatch();

  const getProjects = async() => {
    setSearchLoader(true)
    
    try{
      let res = await CrmUtils.getAllProjects()
      res.data.projects && setProjects(res.data.projects)   
      res.data.projects && dispatch(setAllProjects(res.data.projects))
      store.displayProjects.length === 0 && dispatch(setDisplayProjects(res.data.projects))     
    }catch(error){
      setDisplayProjects([])
    }
    setSearchLoader(false)
  }

  let finalMount = useRef(true)
  useEffect(() => {
    if(finalMount.current){
      finalMount.current = false
      getProjects()
      dispatch(setHeaderMainLink(false))
    }
  }, [])

  return (
    <>
      <div id="main" role="main">

          <Hero partner={props.partner} filters={filtersArray}/>

          <Loader showLoader={searchLoader} color={partnerColor}/>
          
          <Projects partner={props.partner} leadingColor={partnerColor} projects={store.displayProjects}/>

          <BannerText color={partnerColor} content={props.partner.partnerConfig.message}/>
          
      </div>
    </>
  )
}
