import axios from 'axios';
import Utils from '../utils/Utils';

const apiUrl = "https://dev-ruachtova-bubble.azurewebsites.net"
//const apiUrl = "https://prod-ruachtova-bubble.azurewebsites.net"
//const apiUrl = process.env.REACT_APP_API_URL

const URLapiGateway = `https://api.ruachtova.org.il/genericprod`
const URLsearchProjects = `${apiUrl}/api/SearchProject/SearchProjects`
const URLgetPartner = `${apiUrl}/api/Partner/Get`
const URLsendContact = `${apiUrl}/api/ContactUs/Post`
const URLvolunteerMatchList = `${apiUrl}/api/VolunteerMatch/GetList` // /Volunteer/GetMatchList
const URLgetProject = `${apiUrl}/api/Project/Get`
const URLgetAccount = `${apiUrl}/api/Account/Get`
const URLsignUp = `${apiUrl}/api/Volunteer/Post`
const URLvolunteerForm = `${apiUrl}/api/VolunteerForm/Get`
const URLbubbleMatchStatus = `${apiUrl}/api/BubbleMatchStatus/Get`
const URLstaticPage = `${apiUrl}/api/StaticPage/Get`
const URLinitSummary = `${apiUrl}/api/Dashboard/InitSummary`
const URLdashboard = `${apiUrl}/api/Dashboard`
const URLvolunteeringStatus = `${apiUrl}/api/VolunteerMatch/UpdateMatchStatus`
const URLdashboardMangerInfo = `${apiUrl}/api/Dashboard/GetManagerInfo`
const URLdashboardFiltersInfo = `${apiUrl}/api/Dashboard/GetDashboardInfoFilters`

// Add retry interceptor
axios.interceptors.response.use(null, async (error) => {
  console.log("in the interceptor")
  const { config } = error;
  
  // If config doesn't exist or the retry option is not set, reject
  if (!config || !config.retry) {
      return Promise.reject(error);
  }

  // Set the variable for keeping track of the retry count
  config.__retryCount = config.__retryCount || 0;

  // Check if we've maxed out the total number of retries
  if (config.__retryCount >= config.retry) {
      // Reject with the error
      return Promise.reject(error);
  }

  // Increase the retry count
  config.__retryCount += 1;

  // Create new promise to handle exponential backoff
  const backoff = new Promise((resolve) => {
      setTimeout(() => {
          resolve();
      }, config.retryDelay || 1000);
  });

  // Return the promise in which recalls axios to retry the request
  await backoff;
  return axios(config);
});

const getAllProjects = async () => {
    let home_projects = {
      "searchType": 1,  //home
      "resultsCount": 1000,
      "filtersCriteria": []
    }
    
    return await Utils.getItemPost(URLsearchProjects, home_projects)  
}

const getPartner = async (first_login, partner_id) => {
    // First login - try to login to Api and get partner json with admin details
    // Regular - try to login to Api and get partner json with client's token (cookie)
    const config = {
      retry: 3,           // Number of retry attempts
      retryDelay: 1000,   // Delay between retries in milliseconds
    };

    if(first_login){
        return await Utils.getItems(`${URLapiGateway}/get_partner?partner=${partner_id}`, config)
    }else{
        return await Utils.getItems(URLgetPartner, config)
    }
}

const loginFormSubmit = async (req_data) => {
  let url = `${URLapiGateway}/login`;
  let options = {
      method: 'POST',
      data: req_data,
      url
  }
  let res = await axios(options)
  return res
}

const contactFormSubmit = async (e, validate, values) => {
  e.preventDefault();
  if(validate){
    let date = new Date().toISOString();
    let obj = {
        "contactUsID": 0,
        "partnerID": values.id,
        "dateCreated": date,
        "firstName": values.first_name,
        "lastName": values.last_name,
        "email": values.email,
        "phone": values.phone,
        "dateCompleted": date,
        "isArchive": true,
        "isNewsletter": values.newsletter,
        "message": values.comments
    }
    return await Utils.addItemPost(URLsendContact, obj)
  }else{
    throw 'false validation'
  }
}

const getVolunteerMatchList = async () => {
  let res = await Utils.getItems(URLvolunteerMatchList)
  if(res.data.length >= 0){
      var finalArray = res.data.map((item) => {
          return {...item, id: item.bubbleMatchID}
      })
      return finalArray
  }else{
    throw 'error'
  }
}

const getProjectById = async (id) => {
  return await Utils.getItemPost(`${URLgetProject}/${id}`,{})
}

const getMoreProjects = async () => {  
  let clientProjects = {
      "searchType": 5,
      "resultsCount": 50,
      "filtersCriteria": []
    }
  return await Utils.getItemPost(URLsearchProjects, clientProjects)  
}

const signUp = async (signUpJson) => {  
  return await Utils.getItemPost(URLsignUp, signUpJson)  
}

const getUserAccountDetails = async () => {
  return await Utils.getItems(URLgetAccount)
}

const getVolunteerForm = async () => {
  return await Utils.getItems(URLvolunteerForm)
}

const getDashboardFiltersInfo = async () => {
  return await Utils.getItems(URLdashboardFiltersInfo)
}

const getVolunteeringStatus = async (partner_id) => {
  return await Utils.getItems(`${URLbubbleMatchStatus}/Get?partnerID=` + partner_id)
}

const getStaticPage = async (page_id) => {
  return await Utils.getItem(URLstaticPage, page_id)
}

const getDashboardManagerInfo = async (dateFrom, dateTo) => {
  let initReqInfo = {
    dashboardItemType: 0,
    dashboardInfoFilterType: 0,
    dateFrom: dateFrom,
    dateTo: dateTo
  }
  return await Utils.getItemPost(URLdashboardMangerInfo, initReqInfo) 
}

const getDashboardInitSummary = async (dateFrom, dateTo) => {  
  let initReqInfo = {
    dashboardItemType: 1,
    dashboardInfoFilterType: 0,
    dateFrom: dateFrom,
    dateTo: dateTo
  }
  return await Utils.getItemPost(URLinitSummary, initReqInfo)  
}

const updateVolunteeringStatus = async (matchId) => {  
  let delStr = `matchId=${matchId}&statusEnum=3`  //canceled
  return await Utils.updateItem(URLvolunteeringStatus, delStr)  
}

const getPopupGraphInfo = async (url, graphType, filter, dateFrom, dateTo) => {  
  let graphInfo = {
    dashboardItemType: graphType,
    dashboardInfoFilterType: filter,
    dateFrom: dateFrom,
    dateTo: dateTo
  }
  return await Utils.getItemPost(`${URLdashboard}/${url}`, graphInfo)  
}

export default {
  getProjectById, 
  getAllProjects,
  getMoreProjects, 
  getPartner, 
  loginFormSubmit, 
  contactFormSubmit, 
  getVolunteerMatchList,
  getUserAccountDetails,
  getVolunteerForm,
  getVolunteeringStatus,
  getStaticPage,
  getDashboardInitSummary,
  getPopupGraphInfo,
  signUp,
  updateVolunteeringStatus,
  getDashboardManagerInfo,
  getDashboardFiltersInfo
}