(function ($) {
    const windowWidth = $(window).width()

    function openMobileMenu(menu) {
        menu.addClass('show')
        $('body').css({
            'overflow': 'hidden'
        })
        setTimeout(function () {
            menu.addClass('animate')
        }, 100)
        $('.toggle-menu-btn').attr('aria-expanded', 'true');
        $('.menu-close-btn').attr('aria-expanded', 'true');
        $('#main a, #footer a, .mobile-favourite-btn, .breadcrumb, .toggle-menu-btn, .header-logo, input, select, textarea').attr('tabindex', '-1');
    }

    function closeMobileMenu(menu) {
        menu.removeClass('animate')
        setTimeout(function () {
            menu.removeClass('show')
        }, 500)
        $('body').css({
            'overflow': ''
        })
        $('.toggle-menu-btn').attr('aria-expanded', 'false');
        $('.menu-close-btn').attr('aria-expanded', 'false');
        $('#main a, #footer a, .mobile-favourite-btn, .breadcrumb, .toggle-menu-btn, .header-logo, input, select, textarea').attr('tabindex', '');
    }

    function toggleMobileMenu(e) {
        let target = $(e.target)
        if (target.closest('.toggle-menu-btn').length) {
            e.preventDefault()
            openMobileMenu($('.header-collapse-backdrop'));
        }
        if (target.closest('.menu-close-btn').length) {
            e.preventDefault()
            closeMobileMenu($('.header-collapse-backdrop'))
        }
        if (target.hasClass('header-collapse-backdrop')) {
            closeMobileMenu($('.header-collapse-backdrop'))
        }
    }

    function hideDropdowns() {
        $('.dropdown.show').removeClass('show')
        $('.dropdown').attr("aria-expanded", false)
        $('.overlayed').removeClass('overlayed')
    }

    function showDropdown(dropdown) {
        dropdown.addClass('show')
        dropdown.attr("aria-expanded", true)
    }

    function sliceList(listWrapper, itemCount = 6) {
        let citiesList = listWrapper.textContent.split(',')
        let wrapper = $(listWrapper);
        wrapper.text(wrapper.attr('data-default-text'))

        if (citiesList.length > 24 && (windowWidth > 576)) {
            itemCount = 12
        }

        let listContainer = $(`<div class="cities-expanded-list multicolumn-${Math.ceil(citiesList.length / itemCount)}"></div>`)

        for (let i = 0; i < Math.ceil(citiesList.length / itemCount); i++) {
            let list = $('<ul></ul>')
            for (let j = i * itemCount; j < ((i + 1) * itemCount); j++) {
                if (citiesList[j]) {
                    list.append(`<li>${citiesList[j]}</li>`)
                }
            }
            listContainer.append(list)
        }
        if (windowWidth < 640) {
            listContainer.prepend('<div class="close-btn-wrapper-mobile"><div class="btn-wrapper"><span class="close-btn"></span></div></div>')
        }
        wrapper.append(listContainer)
        wrapper.closest('.card-location').addClass('with-cities-list')
    }

    function sliceFilterList(listEl, itemsInColumnCount) {
        const listContainer = $(listEl).find('.list-container')
        const list = listContainer.find("ul")
        const listItems = list.find('li')
        let newListElem = $('<ul>');
        listContainer.html('')
        for (let i = 0; i < listItems.length; i++) {
            if (newListElem.children().length === itemsInColumnCount) {
                listContainer.append(newListElem)
                newListElem = $('<ul>');
            }
            newListElem.append(listItems[i])
            if ((i + 1) >= listItems.length) {
                listContainer.append(newListElem)
            }
        }
    }

    function removeOutlineOnClick() {
        var usingMouse;
        var body = document.body;

        var preFocus = function (event) {
            usingMouse = (event.type === 'mousedown');
        };

        var addFocus = function (event) {
            if (usingMouse)
                event.target.classList.add('focusMouse');
        };

        var removeFocus = function (event) {
            event.target.classList.remove('focusMouse');
        };

        var bindEvents = function () {
            body.addEventListener('keydown', preFocus);
            body.addEventListener('mousedown', preFocus);
            body.addEventListener('focusin', addFocus);
            body.addEventListener('focusout', removeFocus);
        };

        bindEvents();
    }

    function tabNavitagion() {
        $('.field-wrapper>input[type="text"]').on('focus', function () {
            $('.field-wrapper.show').removeClass('show')
            $(this).closest('.field-wrapper').addClass('show')
        })
        $('#city-search').on('focus', function () {
            $('.search-result').addClass('show')
            $(this).closest(".dropdown").find(".dropdown-content").addClass("overlayed")
        })
        $('#city-search').on('blur', function () {
            $('.search-result').removeClass('show')
            $(this).closest(".dropdown").find(".overlayed").removeClass("overlayed")
        })

        $('.search-fields-wrapper .dropdown-content li:last-of-type a').on('blur', function () {
            $(this).closest('.dropdown.show').removeClass('show')
        })
    }

    // cities list
    window.setPos = findCardsWithCitiesList

    function setCitiesListPosition(trigger) {
        const cardContainer = trigger.closest('.card-container')
        const isCardContainer = cardContainer.length > 0
        const card = isCardContainer ? cardContainer : trigger.closest(".record-form__wrapper")
        const locationElement = trigger
        const citiesList = locationElement.find('.cities-expanded-list')
        const wrapper = isCardContainer ? locationElement.closest(".card-wrapper") : trigger.closest(".record-form__wrapper").find(".card-location")
        const paddingRightValue = isCardContainer ? parseInt(window.getComputedStyle(card.find(".card")[0]).getPropertyValue("padding-right")) : parseInt(window.getComputedStyle(card[0]).getPropertyValue("padding-right"))
        const coordinates = locationElement[0].getBoundingClientRect()

        const isCardFullWidth = citiesList.hasClass("multicolumn-3") || citiesList.hasClass("multicolumn-4")

        if (citiesList[0].className.includes('multicolumn') && !citiesList.hasClass('multicolumn-1')) {
            if (wrapper.hasClass("card-wrapper-column")) {
                citiesList.css({
                    position: 'fixed',
                    top: coordinates.top + coordinates.height,
                    left: "auto",
                    right: window.innerWidth - coordinates.right - paddingRightValue,
                    width: isCardFullWidth ? card[0].offsetWidth : "",
                })
                return
            }

            if (wrapper[0].offsetWidth < wrapper[0].scrollWidth) {
                if (card.is(':first-child')) {
                    citiesList.css({
                        position: 'fixed',
                        top: coordinates.top + coordinates.height,
                        left: "auto",
                        right: window.innerWidth - coordinates.right - paddingRightValue
                    })
                    return
                }

                if (card.is(':last-child')) {
                    citiesList.css({
                        position: 'fixed',
                        top: coordinates.top + coordinates.height,
                        left: coordinates.left - paddingRightValue,
                        right: 'auto'
                    })
                    return
                }
                citiesList.css({
                    position: 'fixed',
                    top: coordinates.top + coordinates.height,
                    left: coordinates.right + paddingRightValue - (card.find('.card')[0].offsetWidth / 2) - (citiesList[0].offsetWidth / 2),
                    right: 'auto',
                    transform: 'none'
                })
                return
            }

            if (wrapper[0].offsetWidth === wrapper[0].scrollWidth) {
                switch ((card.index() + 1) % 3) {
                    case 1:
                        citiesList.css({
                            position: 'fixed',
                            top: coordinates.top,
                            left: coordinates.right - citiesList.width() + parseInt(card.find('.card').css('paddingRight')),
                            right: 'auto'
                        })
                        break
                    case 2:
                        citiesList.css({
                            position: 'fixed',
                            top: coordinates.top,
                            left: coordinates.right + parseInt(card.find('.card').css('paddingRight')) - ((card.find('.card').innerWidth()) / 2) - (citiesList.width() / 2),
                            right: 'auto',
                            transform: 'none'
                        })
                        break
                    case 0:
                        citiesList.css({
                            position: 'fixed',
                            top: coordinates.top,
                            left: coordinates.left - parseInt(card.find('.card').css('paddingRight')),
                            right: 'auto'
                        })
                        break
                }
            }
        }
    }

    function findCardsWithCitiesList(trigger) {
        const triggerElement = $(trigger)
        triggerElement.addClass('show-new-list')
        setCitiesListPosition(triggerElement)
    }

    function changeAvatarPosition() {
        const avatar = $('.header__name-block')
        const desktopAvatarParent = $('.header-container')
        const mobileAvatarParent = $('.header-collapse')

        if (windowWidth > 992 && !avatar.parent().is(desktopAvatarParent)) {
            $('.toggle-menu-btn').after(avatar)
        }

        if (windowWidth < 993 && !avatar.parent().is(mobileAvatarParent)) {
            $('#header .menu-close-btn').after(avatar)
        }
    }

    function changeInfoLabelPosition() {
        const isMobile = windowWidth < 768

        if (isMobile) {
            $(".info-row").each((index, row) => {
                const items = $(row).find('.info-item')
                items.each((index, item) => {
                    const infoItem = $(item)
                    const neededClass = infoItem.attr('class').replace("info-item", "")
                    const label = $(`.info-labels strong.${neededClass}`).clone()
                    infoItem.prepend(label)
                })
            })
        }
    }

    function openPopup(popup) {
        $(".wrapper a, .wrapper input, .wrapper button").each((index, item) => {
            $(item).attr("tabindex", "-1")
        })

        popup.hide().css("display", "flex").fadeIn()
        popup.find(".close-popup").focus()
    }

    function closePopup(popup) {
        $(".wrapper a, .wrapper input, .wrapper button").each((index, item) => {
            $(item).removeAttr("tabindex")
        })
        popup.fadeOut()
    }

    function createReportsMobilePreview() {
        const isMobile = windowWidth < 768;

        if (isMobile) {
            $(".reports-info__row:not(.add-report-wrapper)").each((index, row) => {
                const infoRow = $(row)
                const date = infoRow.find('.reports-date').clone()
                const hoursCount = infoRow.find('.reports-hours-count').clone()
                const status = infoRow.find('.reports-status').clone()
                const collapsedBtn = infoRow.find('.collapse-content-btn').clone()

                const preview = $('<div class="mobile-report-preview"> </div>')
                    .append(date)
                    .append(hoursCount)
                    .append(status)
                    .append(collapsedBtn)

                infoRow.before(preview)
            })
        }
    }

    function toggleCommentTooltip() {
        const comments = $(".reports-comment")

        comments.each((index, item) => {
            const comment = $(item)
            const commentWrapper = comment.closest(".reports-comment-wrapper")

            if (comment.height() > 18) {
                comment.addClass("large-comment")
                const tooltip = $(`<div class="reports-info__comment__tooltip"> ${comment.text()} </div>`)
                commentWrapper.append(tooltip)
            }
        })
    }

    function changeProjectInfoImagePosition () {
        const image = $(".project-info__image")
        const desktopParent = $(".project-info")
        const mobileParent = $(".project-info__title")

        if($(window).width() < 768 && !image.parent().is(mobileParent)) {
            mobileParent.append(image)
            return
        }

        if($(window).width() > 767 && !image.parent().is(desktopParent)) {
            desktopParent.append(image)
        }
    }

    $(document).ready(function () {
        changeAvatarPosition();

        $('.event-progress').progressbar({
            value: $('.event-progress').data("progress"),
            max: $('.event-progress').data("max-value")
        })

        changeInfoLabelPosition()

        createReportsMobilePreview()

        $('.event-info__row .collapse-content-btn').on("click", function () {
            const button = $(this)
            const row = button.closest('.event-info__row ');
            const hiddenContent = row.find('.event-info__date.info-item~*')

            if (row.hasClass('collapsed-content')) {
                row.removeClass('collapsed-content')
                hiddenContent.slideUp()
                hiddenContent.slideUp()
                button.attr("aria-expanded", false)
                return
            }

            row.addClass('collapsed-content')
            hiddenContent.css("display", "flex").hide().slideDown()
            button.attr("aria-expanded", true)
        })

        $('.mobile-report-preview .collapse-content-btn').on("click", function () {
            const parent = $(this).closest('.mobile-report-preview')
            const row = parent.next('.reports-info__row')

            if (parent.hasClass("collapsed-content")) {
                parent.removeClass("collapsed-content")
                row.slideUp()
                return
            }

            parent.addClass('collapsed-content')
            row.slideDown()
        })

        $('.reports-info__row .collapse-content-btn').on("click", function () {
            const row = $(this).closest(".reports-info__row")
            const parent = row.prev(".mobile-report-preview")

            parent.removeClass('collapsed-content')
            row.slideUp()
        })

        if (windowWidth > 640) {
            if ($('.reports-info__comment').length) {
                toggleCommentTooltip()
            }
        }

        $(".main-section form").on("submit", e => e.preventDefault())

        $(".dropdown").on("click", function (e) {
            const dropdown = $(this)
            const target = $(e.target)

            if (target.is(dropdown) && dropdown.hasClass("show")) {
                hideDropdowns()
                return
            }

            hideDropdowns()
            showDropdown(dropdown)
        })

        $('.dropdown').on("keydown", function (e) {
            const dropdown = $(this)

            if (e.keyCode === 13 || e.keyCode === 32) {
                e.preventDefault()
                showDropdown(dropdown)
            }
        })

        $(document).on("click", '.dropdown a', function (e) {
            e.preventDefault()

            const link = $(this)
            const field = link.closest('.dropdown').find("input")

            field.val(link.text())
            hideDropdowns()
        })

        $('.add-report-btn, .reports-info .empty-info__btn').on("click", function () {
            const popup = $(".new-report-popup")
            openPopup(popup)
        })

        $(".close-popup").on('click', function () {
            const popup = $(this).closest(".popup-backdrop")
            closePopup(popup)
        })

        $(".popup-backdrop").on("click", function (e) {
            e.stopPropagation()

            const target = $(e.target)
            const popup = $(this)

            if (target.is(popup)) {
                closePopup(popup)
            }
        })

        if (windowWidth < 640) {
            if ($(".new-report-popup").length) {
                $(".new-report-popup h2").attr({'role': "button", "aria-label": "לסגירת טופס דיווח חדש"})
                $(".new-report-popup h2").on("click", function () {
                    const popup = $(this).closest(".popup-backdrop")
                    popup.fadeOut()
                })
            }
        }

        $('.card-location p').each((index, item) => {
            if (parseInt(window.getComputedStyle(item).maxHeight) < item.scrollHeight) {
                windowWidth > 576 ? sliceList(item) : sliceList(item, 4)
            }
        })

        if (windowWidth < 768 && $(".add-report-btn").length) {
            $(".reports-title").append($(".add-report-btn"))
        }

        $('.reports-info__reporting-confirmation .btn').on("click", function () {
            const wrapper = $(this).closest(".info-item")
            wrapper.fadeOut()
        })

        removeOutlineOnClick()

        $(document).on("click", function (e) {
            const dropdownContainer = $(e.target).closest('.dropdown')

            if (!dropdownContainer.length) {
                hideDropdowns()
            }
            toggleMobileMenu(e)
        })

        $(document).keyup(function (e) {
            if (e.key === 'Escape') {
                hideDropdowns()
            }
        })

        $('.dropdown-content').on('click', function (e) {
            if ($(this).find('#city-search').length && $('.search-result.show').length && !($(e.target).closest('.search-result.show, #city-search').length)) {
                $('.search-result.show').removeClass('show')
                $(this).removeClass('overlayed')
            }
        })

        tabNavitagion()

        $(document).on('mouseover click mouseleave', function (e) {
            const target = $(e.target)
            const $this = target.closest('.with-cities-list')

            if (!$this.length) {
                $('.show-new-list').removeClass('show-new-list')
                return
            }
            if (windowWidth > 639) {
                if (e.type === 'mouseover') {
                    findCardsWithCitiesList($this)
                }
                if (e.type === 'mouseleave') {
                    $('.show-new-list').removeClass('show-new-list')
                }
            } else {
                if (e.type === 'click') {
                    findCardsWithCitiesList($this)
                    if (target.closest('.close-btn').length) {
                        $('.show-new-list').removeClass('show-new-list')
                    }
                }
            }
        })

        $(".record-form__form input[type='radio']").on("change", function(){
            const input = $(this)
            const inputId = input.attr("id")
            const recordForm = input.closest(".record-form__form")
            
            window.scrollTo({
                top: $(".record-form__form").offset().top - $("header")[0].offsetHeight - 33,
                behavior: "smooth"
            })

            if(input.is(":checked")) {
                if(inputId === "record-1") {
                    recordForm.removeClass("record-2__active")
                    recordForm.addClass("record-1__active")
                    return
                }

                if(inputId === "record-2") {
                    recordForm.removeClass("record-1__active")
                    recordForm.addClass("record-2__active")
                }
            }
        })

        changeProjectInfoImagePosition()

        $(".who-add .checkbox-list input").on("change", function() {
            const checkbox = $(this)
            const input = checkbox.closest(".dropdown").find("#who-add")
            let checkedCount = 0

            $(".who-add .checkbox-list input").each((index, item) => {
                if($(item).is(":checked")) {
                    checkedCount++
                }
            })

            checkedCount ? input.val(` נבחרו ${checkedCount} עובדים `) : input.val("")
        })
    });

    $(window).on('load resize orientationchange', function () {
        changeAvatarPosition();
    });

    $(window).on('load', function () {
        if (windowWidth > 576) {
            sliceFilterList(".cities-dropdown", 3)
            sliceFilterList(".participation-type-dropdown", 8)
            sliceFilterList(".participants-count-dropdown", 8)
            sliceFilterList(".branches-dropdown", 8)
            sliceFilterList(".category-dropdown", 8)
        }
    })

    $(window).on("resize", function () {
        if (windowWidth > 640 && $('.reports-info__comment').length) {
            toggleCommentTooltip()
        }
        changeProjectInfoImagePosition()

    })

    $(window).on('scroll', function () {
        $('.show-new-list').removeClass('show-new-list')
    })
})(jQuery)